import React, { Component } from "react";

export class About extends Component {
  render() {
    return (
      <React.Fragment>
        This is an about Page. Cetric's todolist react app.
      </React.Fragment>
    );
  }
}

export default About;
